import axios from 'axios';
import config from "../../../../config";

export const FETCH_BLOG_POST_PENDING = 'FETCH_BLOG_POST_PENDING'
export const FETCH_BLOG_POST_SUCCESS = 'FETCH_BLOG_POST_SUCCESS'
export const FETCH_BLOG_POST_ERROR = 'FETCH_BLOG_POST_ERROR'

export function fetchBlogPostPending() {
    return {
        type: FETCH_BLOG_POST_PENDING,
    }
}

export function fetchBlogPostSuccess(blogPosts) {
    return {
        type: FETCH_BLOG_POST_SUCCESS,
        payload: blogPosts
    }
}

export function fetchBlogPostError(error) {
    return {
        type: FETCH_BLOG_POST_ERROR,
        error: error
    }
}

const API_URL = config.API_SERVICE_URL

export function fetchBlogsByEndPoint(endpoint) {

    const requestUrl = API_URL + '/pub/blog/' + endpoint

    return dispatch => {
        dispatch(fetchBlogPostPending());

        axios.get(requestUrl).then((repos) => {
            const blogPost = repos.data;
            dispatch(fetchBlogPostSuccess(blogPost));
            return blogPost;
        })
            .catch(error => {
                dispatch(fetchBlogPostError(error))
            });
    }
}