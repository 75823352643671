import {FETCH_HOME_PAYLOAD_ERROR, FETCH_HOME_PAYLOAD_PENDING, FETCH_HOME_PAYLOAD_SUCCESS} from "../action";


const initialState = {
    payloadPending: false,
    payload: null,
    payloadError: null
}

export function homePayloadReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_HOME_PAYLOAD_PENDING:
            return {
                ...state,
                payloadPending: true
            }
        case FETCH_HOME_PAYLOAD_SUCCESS:
            return {
                ...state,
                payloadPending: false,
                payload: action.payload
            }
        case FETCH_HOME_PAYLOAD_ERROR:
            return {
                ...state,
                payloadPending: false,
                payloadError: action.error
            }
        default:
            return state
    }
}