import axios from 'axios';
import config from "../../../../config";

export const FETCH_NEWS_PENDING = 'FETCH_NEWS_PENDING'
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
export const FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR'

export function fetchNewsPending() {
    return {
        type: FETCH_NEWS_PENDING,
    }
}

export function fetchNewsSuccess(newsPayload) {
    return {
        type: FETCH_NEWS_SUCCESS,
        payload: newsPayload
    }
}

export function fetchNewsError(error) {
    return {
        type: FETCH_NEWS_ERROR,
        error: error
    }
}

const API_URL = config.API_SERVICE_URL

export function fetchNews(page, size) {

    let requestUrl = API_URL + '/pub/news'

    if (page && size) {
        requestUrl += '?page=' + (page - 1) + '&size=' + size;
    }

    return dispatch => {
        dispatch(fetchNewsPending());

        axios.get(requestUrl).then((repos) => {
            const newsPayload = repos.data;
            dispatch(fetchNewsSuccess(newsPayload));
            return newsPayload;
        })
            .catch(error => {
                dispatch(fetchNewsError(error))
            });
    }
}

export function fetchNewsByEndPoint(endpoint) {

    const requestUrl = API_URL + '/pub/news/' + endpoint

    return dispatch => {
        dispatch(fetchNewsPending());

        axios.get(requestUrl).then((repos) => {
            const newsPayload = repos.data;
            dispatch(fetchNewsSuccess(newsPayload));
            return newsPayload;
        })
            .catch(error => {
                dispatch(fetchNewsError(error))
            });
    }
}