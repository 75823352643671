import React from "react";

import "./blog.css"

import {connect} from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import {fetchBlogPayload} from "./action";
import {Helmet} from "react-helmet";

export function parseDate(date) {
    try {
        return moment(date).format('ll');
    } catch {
        return "-";
    }
}

export function titleCase(string) {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence;
}

export function trimString(string, length) {
    return string.length > length ?
        string.substring(0, length - 3) + "..." :
        string;
}

function getFeaturedPost(payload) {
    if (!payload || !payload.body || !payload.body.featuredPost) {
        return null;
    }
    return payload.body.featuredPost;
}

export function getNews(payload) {
    if (!payload || !payload.body || !payload.body.news) {
        return null;
    }
    let news = payload.body.news;
    news = news.length > 2 ? news.slice(0, 3) : news
    return news;
}

export function getStories(payload) {
    if (!payload || !payload.body || !payload.body.stories) {
        return null;
    }
    let stories = payload.body.stories;
    stories = stories.length > 2 ? stories.slice(0, 3) : stories
    return stories;
}

class Blog extends React.Component {

    componentDidMount() {
        this.props.fetchBlogPayload()
    }

    render() {
        const {blogPayload, loading} = this.props;


        const pageToRender = [];

        let featuredPost = getFeaturedPost(blogPayload);

        let featuredPostImage = `https://dummyimage.com/700x350/343a40/6c757d`
        featuredPostImage = (featuredPost && featuredPost.image) ? featuredPost.image + "&fit=crop&w=700&h=350&q=8" : featuredPostImage

        // FeaturedPost
        pageToRender.push(
            <div key={"index-featured-post"}>
                <Helmet><title>Blog - Smarter Technologist</title>
                    <meta name="description" content="Our blog posts at Smarter Technologist!"/>
                    <link rel="canonical" href="https://smartertechnologist.com/blog"/>
                </Helmet>

                <section className="py-5">
                    <div className="container px-5">
                        <h1 className="fw-bolder fs-5 mb-4">Smarter Technologist Blog</h1>
                        {featuredPost &&
                        <div className="card border-0 shadow rounded-3 overflow-hidden">
                            <div className="card-body p-0">
                                <div className="row gx-0">
                                    <div className="col-lg-6 col-xl-5 py-lg-5">
                                        <div className="p-4 p-md-5">
                                            <div className="badge bg-primary bg-gradient rounded-pill mb-2">News</div>
                                            <div
                                                className="h2 fw-bolder">{featuredPost.title ? featuredPost.title : "Untitled post"}</div>
                                            <p>{featuredPost.caption && trimString(featuredPost.caption, 500)}</p>
                                            <a className="stretched-link text-decoration-none"
                                               href={"/blog" + featuredPost.endpoint}>
                                                Read more
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-arrow-right"
                                                     viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-7">
                                        <div className="bg-featured-blog"
                                             style={{backgroundImage: `url(` + featuredPostImage}}/>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section>
            </div>
        );
        {/*"http://placehold.it/750x300"*/
        }

        // News Section
        const newsSectionEntries = [];
        let newsList = getNews(blogPayload);
        if (newsList && newsList.length !== 0) {
            for (const [index, news] of newsList.entries()) {
                newsSectionEntries.push(
                    <div key={"index-news-" + index} className="mb-4">
                        <div className="small text-muted">{parseDate(news.publishDate)}</div>
                        <a className="link-dark" href={"/news" + news.endpoint}>
                            <h3>{news.title ? trimString(news.title, 150) : "Untitled news"}</h3></a>
                    </div>
                )
            }
        } else {
            newsSectionEntries.push(
                <div key={"index-news-empty"} className="text-center">
                    <p className="lead fw-normal text-muted font-italic">Oops... No news at the moment.</p>
                </div>
            )
        }
        pageToRender.push(
            <section key={"index-contact-card"} className="py-5 bg-light">
                <div className="container px-5">
                    <div className="row gx-5">
                        <div className="col-xl-8">
                            <h2 className="fw-bolder fs-5 mb-4">News</h2>
                            {newsSectionEntries}

                            {newsList && newsList.length !== 0 &&
                            <div className="text-end mb-5 mb-xl-0">
                                <a className="text-decoration-none" href={"/news"}>
                                    More news
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-arrow-right"
                                         viewBox="0 0 16 16">
                                        <path fillRule="evenodd"
                                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                    </svg>
                                </a>
                            </div>}
                        </div>
                        <div className="col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-4">
                                    <div className="d-flex h-100 align-items-center justify-content-center">
                                        <div className="text-center">
                                            <div className="h6 fw-bolder">Contact</div>
                                            <p className="text-muted mb-4">
                                                For press inquiries, email us at
                                                <br/>
                                                <a href="#!">smartertechnologist @t gmail.com</a>
                                            </p>
                                            <div className="h6 fw-bolder">Follow us</div>
                                            <a className="fs-5 px-2 link-dark" href="#!">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                                    <path
                                                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                                </svg>
                                            </a>
                                            <a className="fs-5 px-2 link-dark"
                                               href="https://web.facebook.com/smartertechnologist">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                    <path
                                                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                                </svg>
                                            </a>
                                            <a className="fs-5 px-2 link-dark" href="#!">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                    <path
                                                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                                </svg>
                                            </a>
                                            <a className="fs-5 px-2 link-dark" href="#!">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

        // Featured Stories
        const storiesSectionEntries = [];
        let stories = getStories(blogPayload);
        if (stories && stories.length !== 0) {
            for (const [index, story] of stories.entries()) {
                storiesSectionEntries.push(
                    <div key={"index-story-" + index} className="col-lg-4 mb-5">
                        <div className="card h-100 shadow border-0">
                            <img className="card-img-top"
                                 src={story.image ? story.image + "&fit=crop&w=600&h=350&q=8" : "https://dummyimage.com/600x350/ced4da/6c757d"}
                                 alt="..."/>
                            <div className="card-body p-4">
                                <div
                                    className="badge bg-primary bg-gradient rounded-pill mb-2">{story.type ? titleCase(story.type) : 'General'}</div>
                                <a className="text-decoration-none link-dark stretched-link"
                                   href={"/blog" + story.endpoint}>
                                    <div
                                        className="h5 card-title mb-3">{story.title ? trimString(story.title, 150) : "Untitled story"}</div>
                                </a>
                                <p className="card-text mb-0">{story.caption && trimString(story.caption, 500)}</p>
                            </div>
                            <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                <div className="d-flex align-items-end justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <img className="rounded-circle me-3"
                                             src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..."/>
                                        <div className="small">
                                            <div className="fw-bold">{story.author}</div>
                                            <div
                                                className="text-muted">{parseDate(story.publishDate)} &middot; {story.duration} read
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        pageToRender.push(
            // Blog preview section
            <section key={"index-blog-preview"} className="py-5">
                <div className="container px-5">
                    <h2 className="fw-bolder fs-5 mb-4">Featured Stories</h2>
                    <div className="row gx-5">
                        {storiesSectionEntries}
                    </div>
                    {stories && stories.length !== 0 &&
                    <div className="text-end mb-5 mb-xl-0">
                        <a className="text-decoration-none" href={"/stories"}>
                            More stories
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-arrow-right"
                                 viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </a>
                    </div>
                    }
                </div>
            </section>
        );

        if (!stories || stories.length === 0) {
            pageToRender.push(
                <div key={"index-blog-empty"} className="text-center">
                    <p className="lead fw-normal text-muted font-italic">Oops... No stories posts at the moment.</p>
                </div>
            )
        }

        return (
            <div className={"flex-shrink-0 d-flex flex-column px-5 my-5 d-flex flex-column min-vh-100"}>
                {loading ?
                    <div>
                        <Spinner animation="border" role="status" className={"center-item"}>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div> : pageToRender}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        blogPayload: state.blogPayloadReducer.blogPayload,
        loading: state.blogPayloadReducer.blogPayloadPending,
        error: state.blogPayloadReducer.blogPayloadError
    }
}

export default connect(mapStateToProps, {
    fetchBlogPayload,
})(Blog);