import axios from 'axios';
import config from "../../../config";

export const FETCH_HOME_PAYLOAD_PENDING = 'FETCH_HOME_PAYLOAD_PENDING'
export const FETCH_HOME_PAYLOAD_SUCCESS = 'FETCH_HOME_PAYLOAD_SUCCESS'
export const FETCH_HOME_PAYLOAD_ERROR = 'FETCH_HOME_PAYLOAD_ERROR'

export function fetchHomePayloadPending() {
    return {
        type: FETCH_HOME_PAYLOAD_PENDING,
    }
}

export function fetchHomePayloadSuccess(payload) {
    return {
        type: FETCH_HOME_PAYLOAD_SUCCESS,
        payload: payload
    }
}

export function fetchHomePayloadError(error) {
    return {
        type: FETCH_HOME_PAYLOAD_ERROR,
        error: error
    }
}

const API_URL = config.API_SERVICE_URL

export function fetchHomePayload() {

    const requestUrl = API_URL + '/mediator/home'

    return dispatch => {
        dispatch(fetchHomePayloadPending());

        axios.get(requestUrl).then((repos) => {
            const payload = repos.data;
            dispatch(fetchHomePayloadSuccess(payload));
            return payload;
        })
            .catch(error => {
                dispatch(fetchHomePayloadError(error))
            });
    }
}