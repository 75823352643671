import React from 'react';
import {connect} from "react-redux";
import {parseDate, trimString} from "../Blog";
import {fetchNews} from "./action";

import {Pagination as Pager} from 'antd';
import "../blog.css"
import "antd/dist/antd.css";

class News extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
        }
    }

    componentDidMount() {
        this.props.fetchNews(this.state.page, this.state.size)
    }

    render() {

        if (this.props.location) {
            const search = this.props.location.search;
            const page = new URLSearchParams(search).get("p");
        } else {

        }

        const {newsPayload} = this.props;

        const newsToRender = [];

        // News Section
        const newsSectionEntries = [];
        let newsList = newsPayload && newsPayload.body ? newsPayload.body.content : null;

        let totalElements = newsPayload && newsPayload.body ? newsPayload.body.totalElements : 0;
        if (newsList) {
            for (const [index, news] of newsList.entries()) {
                newsSectionEntries.push(
                    <div key={"news-entry-" + index} className="mb-4">
                        <div className="small text-muted">{parseDate(news.publishDate)}</div>
                        <a className="link-dark" href={"/news" + news.endpoint}>
                            <h3>{news.title ? trimString(news.title, 150) : "Untitled news"}</h3></a>
                    </div>
                )
            }
        } else {
            newsSectionEntries.push(
                <div key={"index-empty"} className="text-center">
                    <p className="lead fw-normal text-muted font-italic">Oops... No news at the moment.</p>
                </div>
            )
        }

        const clazz = this

        function onChange(page) {
            clazz.setState({
                page: page
            })
            clazz.props.fetchNews(page, clazz.state.size);
        }

        newsToRender.push(
            <section key={"news-pagination"} className="py-5 bg-light">
                <div className="container px-5">
                    <div className="row gx-5">
                        <div className="col-xl-8">
                            <h2 className="fw-bolder fs-5 mb-4">News</h2>
                            {newsSectionEntries}

                            {newsList &&
                            <nav aria-label="news page navigation" className="pagination justify-content-end">
                                <Pager defaultPageSize={10} defaultCurrent={1} total={totalElements} onChange={onChange}
                                       showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total}`}/>
                            </nav>}
                        </div>
                    </div>
                </div>
            </section>
        );

        return (
            <div className={"flex-shrink-0 px-5 my-5 d-flex flex-column min-vh-100"}>
                {newsToRender}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        newsPayload: state.newsPayloadReducer.newsPayload,
        newsPayloadError: state.newsPayloadReducer.newsPayloadError,
        newsPayloadPending: state.newsPayloadReducer.newsPayloadPending,
    }
}

export default connect(mapStateToProps, {
    fetchNews,
})(News);
