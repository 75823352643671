import React from "react";
import {connect} from "react-redux";
import {fetchHomePayload} from "./action";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";


function parseDate(date) {
    try {
        return moment(date).format('ll');
    } catch {
        return "-";
    }
}

function titleCase(string) {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence;
}

function trimString(string, length) {
    return string.length > length ?
        string.substring(0, length - 3) + "..." :
        string;
}

class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchHomePayload()
    }

    render() {

        let {payload, loading} = this.props;

        const blogsToRender = [];

        if (!payload || !payload.body || payload.body.blogPosts.length === 0) {
            blogsToRender.push(
                <div key={"index-empty"} className="text-center">
                    <p className="lead fw-normal text-muted font-italic">Oops... No blog posts at the moment.</p>
                </div>
            )
        } else {
            let blogPosts = payload.body.blogPosts.length > 2 ? payload.body.blogPosts.slice(0, 3) : payload.body.blogPosts
            for (const [index, post] of blogPosts.entries()) {
                blogsToRender.push(
                    <div key={index} className="col-lg-4 mb-5">
                        <div className="card h-100 shadow border-0">
                            <img className="card-img-top"
                                 src={post.image ? post.image + "&fit=crop&w=600&h=350&q=8" : "https://dummyimage.com/600x350/ced4da/6c757d"}
                                 alt="..."/>
                            <div className="card-body p-4">
                                <div
                                    className="badge bg-primary bg-gradient rounded-pill mb-2">{post.type ? titleCase(post.type) : 'General'}</div>
                                <a className="text-decoration-none link-dark stretched-link"
                                   href={post.endpoint ? "blog" + post.endpoint : "#!"}><h5
                                    className="card-title mb-3">{post.title ? trimString(post.title, 75) : 'Untitled post'}</h5>
                                </a>
                                <p className="card-text mb-0">{post.caption ? trimString(post.caption, 250) : null}</p>
                            </div>
                            <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                <div className="d-flex align-items-end justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <img className="rounded-circle me-3"
                                             src={post.authorImage} alt="..."/>
                                        <div className="small">
                                            <div className="fw-bold">{post.author}</div>
                                            <div
                                                className="text-muted">{parseDate(post.publishDate)} &middot; {post.duration} read
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return (<div>
                <header className="bg-dark py-5">
                    <div className="container px-5">
                        <div className="row gx-5 align-items-center justify-content-center">
                            <div className="col-lg-8 col-xl-7 col-xxl-6">
                                <div className="my-5 text-center text-xl-start">
                                    <h1 className="display-5 fw-bolder text-white mb-2">Making the most out of
                                        technology</h1>
                                    <p className="lead fw-normal text-white-50 mb-4">Maximize the use of latest
                                        technology and really live life!</p>
                                    <div
                                        className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                        <a className="btn btn-primary btn-lg px-4 me-sm-3" href="#features">Get
                                            Started</a>
                                        <a className="btn btn-outline-light btn-lg px-4" href="/about">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img
                                className="img-fluid rounded-3 my-5" src={process.env.PUBLIC_URL + "/resources/images/creative-at-home-flatlay.jpg"}
                                alt="..."/>
                            {/*<p>Photo by <a href="https://burst.shopify.com/@lightleaksin?utm_campaign=photo_credit&amp;utm_content=Free+Stock+Photo+of+Creative+At+Home+Flatlay+%E2%80%94+HD+Images&amp;utm_medium=referral&amp;utm_source=credit">Samantha Hurley</a> from <a href="https://burst.shopify.com/college?utm_campaign=photo_credit&amp;utm_content=Free+Stock+Photo+of+Creative+At+Home+Flatlay+%E2%80%94+HD+Images&amp;utm_medium=referral&amp;utm_source=credit">Burst</a></p>*/}
                            </div>
                        </div>
                    </div>
                </header>

                <section className="py-5" id="features">
                    <div className="container px-5 my-5">
                        <div className="row gx-5">
                            <div className="col-lg-4 mb-5 mb-lg-0"><h2 className="fw-bolder mb-0">Gather information
                                about latest technological concepts and their effects on</h2></div>
                            <div className="col-lg-8">
                                <div className="row gx-5 row-cols-1 row-cols-md-2">
                                    <div className="col mb-5 h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                            <i className="bi bi-collection"/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-collection-fill"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"/>
                                            </svg>
                                        </div>
                                        <h2 className="h5">Globalization</h2>
                                        <p className="mb-0">There is an ever growing worldwide interdependence of people
                                            and countries.</p>
                                    </div>
                                    <div className="col mb-5 h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                            <i className="bi bi-heart-half"/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-heart-half" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                            </svg>
                                        </div>
                                        <h2 className="h5">Health</h2>
                                        <p className="mb-0">Diseases are sometimes said to be the by-product of a
                                            civilized way of life.</p>
                                    </div>
                                    <div className="col mb-5 mb-md-0 h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                            <i className="bi bi-clock-fill"/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-clock-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                            </svg>
                                        </div>
                                        <h2 className="h5">Time</h2>
                                        <p className="mb-0">A precious commodity one cannot afford to waste.</p>
                                    </div>
                                    <div className="col h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                            <i className="bi bi-people-fill"/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                <path fillRule="evenodd"
                                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                            </svg>
                                        </div>
                                        <h2 className="h5">Relationships</h2>
                                        <p className="mb-0">The use of technology should ideally not keep people
                                            physically apart.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="py-5 bg-light">
                    <div className="container px-5 my-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-10 col-xl-7">
                                <div className="text-center">
                                    <div className="fs-4 mb-4 fst-italic">"Technology is a servant of only limited usefulness, and highly unreliable.
                                        When it does solve a problem, it often creates two new ones​—and their side effects are usually hard to foresee."
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img className="rounded-circle me-3"
                                             src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..."/>
                                        <div className="fw-bold">
                                            Donald R. Scoby
                                            <span className="fw-bold text-primary mx-1">/</span>
                                            Environmental Ethics
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="py-5">
                    <div className="container px-5 my-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 col-xl-6">
                                <div className="text-center">
                                    <h2 className="fw-bolder">From our blog</h2>
                                    <p className="lead fw-normal text-muted mb-5">Learn more about digital technology and life.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-5">
                            {loading ?
                                <div>
                                    <div className="container">
                                        <div className="col-lg-8 col-xl-6">
                                            <Spinner animation="border" role="status" style={{
                                                marginTop: '7rem',
                                                flex: 1, alignSelf: 'center', justifyContent: 'center'
                                            }}>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    </div>

                                </div> : blogsToRender}
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        payload: state.homePayloadReducer.payload,
        loading: state.homePayloadReducer.payloadPending,
        error: state.homePayloadReducer.payloadError
    }
}

export default connect(mapStateToProps, {
    fetchHomePayload,
})(Home);