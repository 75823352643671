import {FETCH_BLOG_POST_ERROR, FETCH_BLOG_POST_PENDING, FETCH_BLOG_POST_SUCCESS} from "../action";


const initialState = {
    blogPostPending: false,
    blogPostPayload: null,
    blogPostError: null
}

export function blogPostPayloadReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BLOG_POST_PENDING:
            return {
                ...state,
                blogPostPending: true
            }
        case FETCH_BLOG_POST_SUCCESS:
            return {
                ...state,
                blogPostPending: false,
                blogPostPayload: action.payload
            }
        case FETCH_BLOG_POST_ERROR:
            return {
                ...state,
                blogPostPending: false,
                blogPostError: action.error
            }
        default:
            return state
    }
}