import React from "react";
import {Helmet} from "react-helmet";

class AboutUs extends React.Component {
    render() {
        return (
            <div>
                <Helmet><title>About - Smarter Technologist</title>
                    <meta name="description"
                          content="Our mission is to help you maximize the use of technology and really live life!"/>
                    <link rel="canonical" href="https://smartertechnologist.com/about"/>
                </Helmet>
                <div className="container px-5 my-5 d-flex flex-column min-vh-100">
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-8 col-xl-6">
                            <div className="text-center">
                                <h2 className="fw-bolder">About Us</h2>
                                <p className="lead fw-normal text-muted mb-5">Our mission is to help you maximize the
                                    use of technology and really live life!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;