import React from "react";
import {Helmet} from "react-helmet";

class AboutUs extends React.Component {
    render() {
        return (
            <div>
                <Helmet><title>Privacy Policy - Smarter Technologist</title>
                    <meta name="description" content="Our privacy policy!"/>
                    <link rel="canonical" href="https://smartertechnologist.com/privacy"/>
                </Helmet>
                <div className="container px-5 my-5 d-flex flex-column min-vh-100">
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-8 col-xl-6">

                            <h1 className="mt-4">We Respect Your Privacy</h1>
                            <p className="lead">
                                Updated 27 September, 2021
                            </p>

                            <div className="lead fw-normal text-muted mb-5">
                                This privacy policy governs your use of our services. It is meant to help you understand
                                what information we collect, why we collect it and how you can manage your information.
                            </div>

                            <div style={{marginTop: -30, marginBottom: 10}}>
                                The following outlines our privacy policy:
                            </div>

                            {/* Post Content */}
                            <div className="fw-normal mb-5">
                                <ul>
                                    <li>Before or at the time of collecting personal information, we will identify the
                                        purposes for which information is being collected.
                                    </li>
                                    <li>We will only retain personal information as long as necessary for the
                                        fulfillment of those purposes.
                                    </li>
                                    <li>We will protect personal information by reasonable security safeguards against
                                        loss or theft, as well as unauthorized access, disclosure, copying, use or
                                        modification.
                                    </li>
                                    <li>We will make readily available to customers information about our policies and
                                        practices relating to the management of personal information.
                                    </li>
                                    <li>We will collect and use of personal information solely with the objective of
                                        fulfilling those purposes specified by us and for other compatible purposes,
                                        unless we obtain the consent of the individual concerned or as required by law.
                                    </li>
                                    <li>Personal data should be relevant to the purposes for which it is to be used,
                                        and, to the extent necessary for those purposes, should be accurate, complete,
                                        and up-to-date.
                                    </li>
                                    <li>We will make readily available to customers information about our policies and
                                        practices relating to the management of personal information.
                                    </li>
                                </ul>

                                We are committed to conducting our business in accordance with these principles in order
                                to ensure that the confidentiality of personal information is protected and maintained.

                                <br/>
                                <span>&nbsp;</span>
                                <p><b><span>What are my opt-out rights?</span></b></p>
                                <p>You can stop all collection of information easily by uninstalling our applications or
                                    deleting your account information on our services.</p>

                                <p><b><span>Data Retention Policy, Managing Your Information</span></b></p>
                                <p>We will retain automatically collected information for up to 24 months and thereafter
                                    may store it in aggregate. </p>

                                <p><b><span>Children</span></b></p>
                                <p>We do not use our services to knowingly solicit data from or appeal to children under
                                    the age of 13. If a parent or guardian becomes aware that his or her child has
                                    provided us with information without their consent, he or she should contact us at
                                    smartertechnologist @t gmail.com. We will delete such information from our files
                                    within a reasonable time.</p>

                                <p><b><span>Changes to this policy</span></b></p>
                                <p>This Privacy Policy may be updated from time to time for any reason. You are advised
                                    to consult this Privacy Policy regularly for any changes, as continued use is deemed
                                    approval of all changes. </p>

                                <p><b><span>Your consent</span></b></p>
                                <p>By using our applications and services, you are consenting to our processing of your
                                    information as set forth in this Privacy Policy now and as amended by us.
                                    "Processing,” means using cookies on a computer/hand held device or using or
                                    touching information in any way, including, but not limited to, collecting, storing,
                                    deleting, using, combining and disclosing information, all of which activities will
                                    take place in the United States. If you reside outside the United States your
                                    information will be transferred, processed and stored there under United States
                                    privacy standards.</p>

                                <p><b><span>Contact us</span></b></p>
                                <p>If you have any questions regarding privacy while using our services, or have
                                    questions about our practices, please contact us via email at smartertechnologist @t
                                    gmail.com.</p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;