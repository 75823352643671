import React from "react";
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import {Col, Container, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import MarkdownPreview from '@uiw/react-markdown-preview';
import {fetchNewsByEndPoint} from "./action";

function parseDate(date) {
    try {
        return moment(date).format('ll');
    } catch {
        return "-";
    }
}

function parseTime(date) {
    try {
        return moment(date).format('LT');
    } catch {
        return "-";
    }
}

class NewsItem extends React.Component {

    componentDidMount() {
        const {endpoint} = this.props.match.params;
        this.props.fetchNewsByEndPoint(endpoint)
    }

    render() {

        const {newsPayload, newsPayloadError} = this.props;

        const newsToRender = [];

        if (!newsPayload || !newsPayload.body) {
            newsToRender.push(
                <div key={"index-empty-post"} className="text-center">
                    {newsPayloadError &&
                    <p className="lead fw-normal text-muted font-italic">Oops... An error occurred while fetching blog
                        post.
                    </p>
                    }
                </div>
            )
        } else {

            const newsItem = newsPayload.body;

            newsToRender.push(
                <div key={"index-blog-post"}>
                    <Row className="row">
                        <Col className={"col-lg-8"}>
                            <h1 className="mt-4">{newsItem.title}</h1>
                            <p className="lead">
                                {parseDate(newsItem.publishDate)}
                            </p>

                            {newsItem.featuredImage &&
                            <img className="img-fluid rounded" src="http://placehold.it/900x300" alt=""/>}

                            {/* Post Content */}
                            <MarkdownPreview source={newsItem.article} style={{}}/>
                        </Col>
                    </Row>
                </div>
            )
        }

        return (
            <Container className={"container px-5 d-flex flex-column min-vh-100"}>
                {newsToRender}
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        newsPayload: state.newsPayloadReducer.newsPayload,
        newsPayloadError: state.newsPayloadReducer.newsPayloadError,
        newsPayloadPending: state.newsPayloadReducer.newsPayloadPending,
    }
}

export default withRouter(connect(mapStateToProps, {
    fetchNewsByEndPoint,
})(NewsItem));