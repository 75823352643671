import axios from 'axios';
import config from "../../../config";

export const FETCH_BLOG_PAYLOAD_PENDING = 'FETCH_BLOG_PAYLOAD_PENDING'
export const FETCH_BLOG_PAYLOAD_SUCCESS = 'FETCH_BLOG_PAYLOAD_SUCCESS'
export const FETCH_BLOG_PAYLOAD_ERROR = 'FETCH_BLOG_PAYLOAD_ERROR'

export function fetchBlogPayloadPending() {
    return {
        type: FETCH_BLOG_PAYLOAD_PENDING,
    }
}

export function fetchBlogPayloadSuccess(blogPayload) {
    return {
        type: FETCH_BLOG_PAYLOAD_SUCCESS,
        payload: blogPayload
    }
}

export function fetchBlogPayloadError(error) {
    return {
        type: FETCH_BLOG_PAYLOAD_ERROR,
        error: error
    }
}

const API_URL = config.API_SERVICE_URL

export function fetchBlogPayload() {

    const requestUrl = API_URL + '/mediator/blog'

    return dispatch => {
        dispatch(fetchBlogPayloadPending());

        axios.get(requestUrl).then((repos) => {
            const allBlogs = repos.data;
            dispatch(fetchBlogPayloadSuccess(allBlogs));
            return allBlogs;
        })
            .catch(error => {
                dispatch(fetchBlogPayloadError(error))
            });
    }
}