import {combineReducers} from 'redux'
import {homePayloadReducer} from "../components/home/reducers";
import {blogPayloadReducer} from "../components/blog/reducers";
import {blogPostPayloadReducer} from "../components/blog/blog-post/reducers";
import {newsPayloadReducer} from "../components/blog/news/reducers";

const rootReducer = combineReducers({
    homePayloadReducer,
    blogPayloadReducer,
    blogPostPayloadReducer,
    newsPayloadReducer
})

export default rootReducer
