import {FETCH_BLOG_PAYLOAD_ERROR, FETCH_BLOG_PAYLOAD_PENDING, FETCH_BLOG_PAYLOAD_SUCCESS} from "../action";


const initialState = {
    blogPayloadPending: false,
    blogPayload: null,
    blogPayloadError: null
}

export function blogPayloadReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BLOG_PAYLOAD_PENDING:
            return {
                ...state,
                blogPayloadPending: true
            }
        case FETCH_BLOG_PAYLOAD_SUCCESS:
            return {
                ...state,
                blogPayloadPending: false,
                blogPayload: action.payload
            }
        case FETCH_BLOG_PAYLOAD_ERROR:
            return {
                ...state,
                blogPayloadPending: false,
                blogPayloadError: action.error
            }
        default:
            return state
    }
}