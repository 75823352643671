const configMap = {
  development: {
    API_SERVICE_URL: 'http://localhost:8055/api/v1',
    COMMENTO_URL: 'http://localhost:8077',
  },
  staging: {
    API_SERVICE_URL: 'https://staging.exampleapi.com/api/v1',
  },
  production: {
    API_SERVICE_URL: 'https://stech-service.herokuapp.com/api/v1',
    COMMENTO_URL: 'https://st-commento.herokuapp.com',
  },
}

const config = configMap[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'development']

export default {
  ...config,
}
