import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => (
    <div>
        <div className="container px-5 my-5 d-flex flex-column min-vh-100">
            <div className="row gx-5 justify-content-center">
                <div className="col-lg-8 col-xl-6">
                    <div className="text-center">
                        <h2 className="fw-bolder">Page Not Found</h2>
                        <p className="lead fw-normal text-muted mb-5">Looks like you followed a broken link or entered a
                            non-existent URL</p>
                        <Link to="/">
                            Go Home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default NotFound;