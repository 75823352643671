import React from "react";
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import MarkdownPreview from '@uiw/react-markdown-preview';

import "./blogpost.css"
import {titleCase} from "../Blog";
import {fetchBlogsByEndPoint} from "./action";
import Commento from "./commento";

function parseDate(date) {
    try {
        return moment(date).format('ll');
    } catch {
        return "-";
    }
}

function parseTime(date) {
    try {
        return moment(date).format('LT');
    } catch {
        return "-";
    }
}

function commaSeparate(list) {
    if (!list) {
        return null;
    }
    return list.join(", ");
}

function buildCategories(categories) {
    if (!categories) {
        return null;
    }

    let categoriesList = [];
    for (const [index, category] of categories.entries()) {
        categoriesList.push(
            <a key={"index-blog-post-article-category-" + index}
               className="badge bg-secondary text-decoration-none link-light" href="#!" style={{margin: '1px'}}>
                {category && titleCase(category)}
            </a>
        )
    }
    return categoriesList;
}

class BlogPost extends React.Component {

    componentDidMount() {
        const {endpoint} = this.props.match.params;
        this.props.fetchBlogsByEndPoint(endpoint)
    }

    render() {

        const {blogPostPayload, blogPostPayloadError} = this.props;

        const blogPostToRender = [];

        if (!blogPostPayload || !blogPostPayload.body) {
            if (blogPostPayloadError) {
                blogPostToRender.push(
                    <div key={"index-empty-post"} className="text-center">
                        <p className="lead fw-normal text-muted font-italic">Oops... An error occurred while fetching
                            blog
                            post.</p>
                    </div>
                )
            }
        } else {

            const blogPost = blogPostPayload.body;

            blogPost.comments = [
                {
                    id: 1,
                    author: "John Brown",
                    body: "If you're going to lead a space frontier, it has to be government; it'll never be private enterprise. Because the space frontier is dangerous, and it's expensive, and it has unquantified risks.",
                    children: [
                        {
                            id: 6,
                            author: "Matt Brush",
                            body: "And under those conditions, you cannot establish a capital-market evaluation of that enterprise. You can't get investors.",
                        },
                        {
                            id: 7,
                            author: "Matt Brush",
                            body: "When you put money directly to a problem, it makes a good headline.",
                        }
                    ]
                },
                {
                    id: 2,
                    author: "Matt Brush",
                    body: "When I look at the universe and all the ways the universe wants to kill us, I find it hard to reconcile that with statements of beneficence."
                },
                {
                    id: 2,
                    author: "John Brown",
                    body: "react code is easier than u think"
                },
                {
                    id: 4,
                    author: "Matt Brush",
                    body: "i think react is better than angular"
                },
                {
                    id: 5,
                    author: "Matt Brush",
                    body: "i think react is better than angular"
                }
            ];

            blogPostToRender.push(
                <div key={"index-blog-post"}>

                    <div className="row gx-5">
                        <div className="col-lg-3">
                            <div className="d-flex align-items-center mt-lg-5 mb-4">
                                <img className="img-fluid rounded-circle"
                                     src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..."/>
                                <div className="ms-3">
                                    <div className="fw-bold"><a href="#" className="link-dark">{blogPost.author}</a>
                                    </div>
                                    <div className="text-muted">{blogPost.tags && commaSeparate(blogPost.tags)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <article key={"index-blog-post-article"}>
                                <header className="mb-4" key={"index-blog-post-article-header"}>
                                    <h1 className="fw-bolder mb-1">{blogPost.title}</h1>
                                    <div className="text-muted fst-italic mb-2">{parseDate(blogPost.publishDate)}</div>
                                    {blogPost.categories && buildCategories(blogPost.categories)}
                                </header>
                                <figure className="mb-4"><img className="img-fluid rounded"
                                                              src={blogPost.image ? blogPost.image + "&fit=crop&w=900&h=400&q=8" : "https://dummyimage.com/900x400/ced4da/6c757d.jpg"}
                                                              alt="..."/></figure>
                                <section className="mb-5">
                                    <MarkdownPreview className="fs-5 mb-4 blog-post" source={blogPost.article}
                                                     style={{}}/>
                                </section>
                            </article>

                            {blogPost.commentable &&
                            <section>
                                <Commento/>
                            </section>}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"flex-column min-vh-100"}>
                <section className="py-5">
                    <div className="container px-5 my-5">
                        {blogPostToRender}
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        blogPostPayload: state.blogPostPayloadReducer.blogPostPayload,
    }
}

export default withRouter(connect(mapStateToProps, {
    fetchBlogsByEndPoint,
})(BlogPost));