import React from 'react'
import {Nav, Navbar} from 'react-bootstrap'

class BootstrapNavbar extends React.Component {

    render() {
        return (
            <div>
                <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                    <div className={"container px-5"}>
                        <Navbar.Brand href="/">Smarter Technologist</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/blog">Blog</Nav.Link>
                            </Nav>
                            {/*<ApiSearch/>*/}
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        )
    }
}

export default BootstrapNavbar;