import {FETCH_NEWS_ERROR, FETCH_NEWS_PENDING, FETCH_NEWS_SUCCESS} from "../action";


const initialState = {
    newsPayloadPending: false,
    newsPayload: null,
    newsPayloadError: null
}

export function newsPayloadReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_NEWS_PENDING:
            return {
                ...state,
                newsPayloadPending: true
            }
        case FETCH_NEWS_SUCCESS:
            return {
                ...state,
                newsPayloadPending: false,
                newsPayload: action.payload
            }
        case FETCH_NEWS_ERROR:
            return {
                ...state,
                newsPayloadPending: false,
                newsPayloadError: action.error
            }
        default:
            return state
    }
}